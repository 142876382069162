import { Router } from "@reach/router"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Posts from "components/discoverPage/Posts"

const ManageMyEarlyMenopausePage = () => {
  return (
    <Layout>
      <SEO title="Manage my early menopause" />
      <Router>
        <Posts title="Manage my early menopause" path="/manage" />
      </Router>
    </Layout>
  )
}
export default ManageMyEarlyMenopausePage
